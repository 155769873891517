<template>
  <b-form class="user-form" @submit.prevent="onUserSubmit">
    <div class="user-form-header mb-4 px-4">
      <b-avatar
        button
        class="mb-2 avatar-edit"
        badge-variant="transparent"
        variant="light"
        size="60"
        :src="avatarUrl"
        @click="onAvatarClick"
      >
        <template #badge v-if="avatarUrl">
          <i class="simple-icon-camera"></i>
        </template>
      </b-avatar>
      <p v-if="editMode" class="font-extra-light mb-0">
        <i class="fal fa-pen mr-1"></i>
        <span>Edit User</span>
      </p>
      <p v-else class="font-extra-light mb-0">
        <i class="fal fa-paper-plane mr-1"></i>
        <span>Creating & Inviting User</span>
      </p>
      <h2 v-if="userData.name.trim()">{{ userData.name }}</h2>
      <h2 v-else>[Name]</h2>
      <input
        id="fileUpload"
        ref="avatarFile"
        type="file"
        accept="image/jpg,image/png"
        @change="onAvatarFileChange"
        hidden
      />
    </div>

    <div class="user-form-body px-4">
      <h6 class="font-bold">Details</h6>
      <b-row>
        <b-col class="col-md-6 mb-3">
          <base-input
            label="First Name"
            placeholder="First Name"
            v-model="userData.firstName"
            validate
            required
            tooltipClass="z-index-1060"
            :v="$v.userData.firstName"
            :error="setError($v.userData.firstName, 'First Name')"
          ></base-input>
        </b-col>
        <b-col class="col-md-6 mb-3 pl-0">
          <base-input
            label="Last Name"
            placeholder="Last Name"
            v-model="userData.lastName"
            validate
            required
            tooltipClass="z-index-1060"
            :v="$v.userData.lastName"
            :error="setError($v.userData.lastName, 'Last Name')"
          ></base-input>
        </b-col>
        <b-col class="col-md-6 mb-3">
          <base-input
            label="Email"
            placeholder="Email"
            required
            type="email"
            v-model="userData.email"
            validate
            tooltipClass="z-index-1060"
            :disabled="editMode || !adminMode"
            :error="setError($v.userData.email, 'Email')"
            :v="$v.userData.email"
          ></base-input>
        </b-col>
        <b-col v-if="adminMode" class="col-md-6 mb-3 pl-0">
          <base-selection
            class="role-selection"
            label="Account Role"
            placeholder="Role"
            v-model="selectedRole"
            itemValue="name"
            validate
            required
            readonly
            tooltipClass="z-index-1060"
            :error="setError($v.userData.accountRoleId, 'Role')"
            :options="accountRoles"
            :v="$v.userData.accountRoleId"
          ></base-selection>
        </b-col>
      </b-row>

      <div v-if="!selectedAdminRole" class="d-flex flex-column mb-2">
        <div class="d-flex">
          <h6 class="align-self-center font-bold mb-0">
            <span>Game</span>
          </h6>
          <b-button
            v-if="adminMode"
            class="align-self-center ml-auto"
            size="xs"
            variant="outline-primary"
            @click="onSimulationAdd"
          >
            <span class="label">Add</span>
          </b-button>
        </div>
        <simulation-selection-fields-list
          class="mb-4"
          ref="simulationSelection"
          v-model="userData.simulations"
          :canRemove="adminMode"
          :simulationMode="simulationMode"
          :simulations="simulations"
          :simulationRoles="simulationRoles"
        >
        </simulation-selection-fields-list>
      </div>

      <h6 v-if="resetPassword" class="font-bold">Account</h6>
      <b-row>
        <transition name="fade">
          <b-col class="col-md-6 mb-3" v-if="resetPassword">
            <base-input
              label="Password"
              placeholder="Password"
              v-model="userData.password"
              validate
              required
              tooltipClass="z-index-1060"
              type="password"
              :v="$v.userData.password"
              :error="setError($v.userData.password, 'Password')"
            ></base-input>
          </b-col>
        </transition>
        <transition name="fade">
          <b-col class="col-md-6 mb-3" v-if="resetPassword">
            <base-input
              label="Confirm Password"
              placeholder="Confirm Password"
              v-model="userData.confirmPassword"
              validate
              required
              tooltipClass="z-index-1060"
              type="password"
              :v="$v.userData.confirmPassword"
              :error="setError($v.userData.confirmPassword, 'Confirm Password')"
            ></base-input>
          </b-col>
        </transition>
      </b-row>
    </div>

    <div class="d-flex px-4">
      <div class="align-self-center">
        <b-form-checkbox
          v-if="editMode"
          id="reset-password-checkbox"
          v-model="resetPassword"
          name="reset-password-checkbox"
          :value="true"
          :unchecked-value="false"
          class="align-items"
        >
          Reset password?
        </b-form-checkbox>
      </div>
      <div class="align-self-center ml-auto">
        <b-button variant="light" size="sm" @click="onCancelClick">
          <span class="label">Cancel</span>
        </b-button>
        <processing-button
          variant="primary"
          size="sm"
          :label="editMode ? 'Update' : 'Save'"
          class="ml-2"
          :processing="processingUser"
        ></processing-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import { isPasswordValid, errorMessages } from '@/utils/validators'
import { initializeSimulationSelection } from '@/utils/initialization'
import { initializeUser } from '@/utils/initialization'
import { role } from '@/constants/config'
import { userRoles } from '@/data/admin'
import { validationMixin } from 'vuelidate'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import SimulationSelectionFields from './SimulationSelectionFields.vue'
import SimulationSelectionFieldsList from './SimulationSelectionFieldsList.vue'
export default {
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton,
    PulseLoader,
    SimulationSelectionFields,
    SimulationSelectionFieldsList
  },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    simulationMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      avatarUrl: '',
      errorMessages,
      resetPassword: false,
      roles: userRoles,
      selectedRole: null,
      selectedSimulationRole: null,
      teams: [],
      userData: initializeUser(),
      userDataForm: this.createNewUserForm()
    }
  },
  validations() {
    if (!this.resetPassword) {
      return {
        userData: {
          email: { required, email, emailMinLength: minLength(4) },
          firstName: { required },
          lastName: { required },
          accountRoleId: { required }
        }
      }
    } else {
      return {
        userData: {
          email: { required, email, emailMinLength: minLength(4) },
          firstName: { required },
          lastName: { required },
          accountRoleId: { required },
          password: {
            required,
            passwordMaxLength: maxLength(16),
            passwordMinLength: minLength(8),
            isPasswordValid: isPasswordValid
          },
          confirmPassword: {
            samePassword: sameAs('password')
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['addUser', 'fetchUser', 'updateUser']),
    createNewUserForm() {
      return new FormData()
    },
    getUser() {
      this.userData.id = this.user.id
      this.userData.firstName = this.user.firstName
      this.userData.lastName = this.user.lastName
      this.userData.email = this.user.email
      this.userData.avatar = this.user.avatar
      this.avatarUrl = this.user.avatar
      this.userData.accountRoleId = this.accountRoles.find(
        (role) => role.id === user.accountRoleId
      ).id
      this.selectedRole = {
        id: this.userData.role,
        name: this.user.accountRoleId
      }
    },
    onAvatarClick() {
      this.$refs['avatarFile'].click()
    },
    onAvatarFileChange(event) {
      let files = event.target.files
      const file = files[0]
      this.userData.avatar = file
      this.avatarUrl = URL.createObjectURL(file)
    },
    setError(item, name) {
      if (item.$dirty) {
        let errorMessage = ''
        let errorMessages = this.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${errorMessages.emailMinLength}`
          return errorMessage
        }

        if (item.passwordMaxLength === false) {
          errorMessage = `${errorMessages.passwordMaxLength}`
          return errorMessage
        }

        if (item.passwordMinLength === false) {
          errorMessage = `${errorMessages.passwordMinLength}`
          return errorMessage
        }

        if (item.isPasswordValid === false) {
          errorMessage = `${errorMessages.isPasswordValid}`
          return errorMessage
        }

        if (item.samePassword === false) {
          errorMessage = `${errorMessages.samePassword}`
          return errorMessage
        }
      }
    },
    onCancelClick() {
      this.$emit('userCancelled')
    },
    onSimulationAdd() {
      // find new simulation in the list
      const newSimulationIndex = this.userData.simulations.findIndex(
        (simulation) => simulation.id === null
      )

      if (newSimulationIndex !== -1) {
        return
      }

      const simulation = initializeSimulationSelection()
      this.userData.simulations.push(simulation)
    },
    async onUserSubmit() {
      this.$v.userData.$touch()
      const simulationSelectionValidation = this.selectedAdminRole
        ? true
        : this.$refs['simulationSelection'].validateSimulationSelections()

      if (this.$v.userData.$pending || this.$v.userData.$error || !simulationSelectionValidation)
        return

      // submit form
      this.userDataForm.append('first_name', this.userData.firstName)
      this.userDataForm.append('last_name', this.userData.lastName)
      this.userDataForm.append('email', this.userData.email)
      this.userDataForm.append('account_role_id', this.selectedRole.id)

      if (this.avatarUrl && this.isAvatarUrlLocal) {
        this.userDataForm.append('avatar', this.userData.avatar)
      }

      if (this.resetPassword) {
        this.userDataForm.append('password', this.userData.password)
        this.userDataForm.append('confirm-password', this.userData.confirmPassword)
      }

      this.userData.simulations.forEach((simulation, index) => {
        if (simulation.id) {
          this.userDataForm.append(`simulations[${index}][id]`, simulation.id)
          this.userDataForm.append(
            `simulations[${index}][simulation_role_id]`,
            simulation.simulationRoleId
          )

          if (simulation.simulationTeamId) {
            this.userDataForm.append(
              `simulations[${index}][simulation_team_id]`,
              simulation.simulationTeamId
            )
          }
        }
      })

      if (this.editMode) {
        this.userDataForm.append('id', this.userData.id)
        await this.updateUser(this.userDataForm)
      } else {
        await this.addUser(this.userDataForm)
      }

      this.formChanged = false
      this.$emit('userSaved', true)

      this.resetUserData()
    },
    async resetUserData() {
      this.userData = initializeUser()
      this.userDataForm = this.createNewUserForm()
      this.$v.$reset()
    }
  },
  computed: {
    ...mapGetters([
      'accountRoles',
      'allUsers',
      'currentSimulation',
      'currentUser',
      'simulations',
      'simulationRoles',
      'simulationTeams'
    ]),
    ...mapGetters('loading', ['loadingSimulationTeams', 'processingUser']),
    formHeaderInfo() {
      return this.editMode ? 'Edit User' : 'Add User'
    },
    hasGameCreatorRole() {
      return (
        this.currentUser && [role.GAMECREATOR, role.ADMIN].includes(this.currentUser.accountRoleId)
      )
    },
    isAvatarUrlLocal() {
      let url = this.avatarUrl
      let regex = /^blob:/
      return regex.test(url)
    },
    selectedAdminRole() {
      return this.selectedRole && this.selectedRole.id === 1
    },
    sortedSimulationTeams() {
      return this.simulationTeams.sort((a, b) => a.name.localeCompare(b.name))
    },
    user: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.resetPassword = !this.editMode
  },
  watch: {
    selectedRole: {
      handler(newValue) {
        if (newValue) {
          this.userData.accountRoleId = newValue.id

          if ([2, 3].includes(newValue.id) && this.userData.simulations.length === 0) {
            this.onSimulationAdd()
          }
        }
      }
    },
    user: {
      handler(newValue) {
        if (newValue) {
          this.userData = _.cloneDeep(newValue)

          if (newValue.accountRoleId) {
            this.selectedRole = {
              id: newValue.accountRoleId,
              name: this.accountRoles.find((role) => role.id === newValue.accountRoleId).name
            }
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form-body {
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
